@import "nlib/config";

.progress {
  flex: auto;
  .title {
    display: flex;
    font-weight: 600;
    line-height: 1;
    .text {
      margin-right: auto;
    }
    .percentage {
      margin-left: $contentDefaultIndent;
    }
  }
  .bar {
    height: 6px;
    margin-top: 4px;
    border-radius: 6px;
    background: #e9ecef;
    .level {
      height: 6px;
      /* stylelint-disable plugin/no-low-performance-animation-properties */
      transition: width 0.2s;
      /* stylelint-enable plugin/no-low-performance-animation-properties */
      border-radius: 6px;
      background-color: $uiHighlightColor;
    }
  }
  &.fetching {
    .percentage,
    .level {
      display: none;
    }
  }
}
