@import "nlib/config";

.mergeModal {
  width: 420px !important;
  :global(.modal) {
    &-body {
      padding-right: $contentDefaultIndent * 2.5;
      padding-left: $contentDefaultIndent * 2.5;
    }
  }
  .text,
  .select {
    margin-bottom: $contentDefaultIndent;
  }
  .list {
    .item {
      display: flex;
      align-items: center;
      height: 2.5rem;
      padding: 0 $contentDefaultIndent;
      border-radius: $uiBorderRadius;
      background-color: $uiBackgroundColor;
      white-space: nowrap;
      svg {
        min-width: 1rem;
        font-size: 1rem;
      }
      div {
        margin-left: 0.3em;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      + .item {
        margin-top: $contentDefaultIndent;
      }
    }
  }
  .check {
    margin-top: $contentDefaultIndent;
    > span {
      margin-left: $contentDefaultIndent;
    }
  }
}
