@import "nlib/config";

.amountCell {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 2px;
  padding-bottom: 8px;
  .split {
    height: 18px;
  }
  .amount {
    display: flex;
    align-items: center;
    height: 2.5rem;
    font-size: 0.9375rem;
    font-weight: 500;
    text-overflow: ellipsis;
  }
  .amountTotal {
    display: flex;
    position: relative;
    height: 18px;
    margin-top: 8px;
    color: $uiNeutralColor;
    font-size: $uiDesktopFontSize;
    line-height: 1.2;
    &:hover {
      color: inherit;
    }
    .total {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
