@import "nlib/config";

.amountDeviationFilters {
  display: flex;
  align-items: center;
  padding-left: $contentDefaultIndent;
  .select {
    width: 6rem;
    margin-right: $contentDefaultIndent * 2;
    &:not(:focus) {
      border-color: $uiBorderColor;
    }
    button {
      height: 2rem !important;
    }
  }
  .input {
    width: 6rem;
    input {
      height: 2rem;
    }
    &:not(:focus) {
      border-color: $uiBorderColor;
    }
  }
  .dash {
    margin: 0 $contentDefaultIndent;
  }
  .currency {
    margin-left: $contentDefaultIndent;
    font-size: $uiDesktopFontSize;
  }
}
