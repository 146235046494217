@import "nlib/config";

.category {
  .header {
    display: flex;
    align-items: center;
    height: 2.75rem;
    padding: 0 $contentDefaultIndent;
    white-space: nowrap;
    cursor: pointer;
    &.opened,
    &:hover {
      background-color: $uiBackgroundColor;
    }
    .percents {
      flex: none;
      width: 30px;
      margin-right: $contentDefaultIndent;
      border-radius: $contentDefaultIndent / 2;
      font-size: 0.85em;
      font-weight: 500;
      text-align: center;
    }
    .displayName {
      margin-right: $contentDefaultIndent * 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .transactionsCount {
      display: flex;
      align-items: center;
      height: 2rem;
      margin-left: auto;
      padding: 0 $contentDefaultIndent;
      border-radius: $uiBorderRadius;
      background-color: darken($uiBackgroundColor, 2%);
      color: $uiBlackColor;
      text-transform: lowercase;
    }
    .changeCategory {
      margin-left: $contentDefaultIndent * 2;
    }
    .markAsCorrect {
      margin-left: $contentDefaultIndent * 2;
    }
    .icon {
      margin-right: $contentDefaultIndent;
      font-size: 1rem;
    }
  }
  &:not(:last-child) {
    border-bottom: 1px solid $uiBorderColor;
  }
  .transactionsTable {
    .tableHead,
    .tableCell {
      padding-right: $contentDefaultIndent;
      padding-left: $contentDefaultIndent;
      border-right: none !important;
      border-left: none !important;
      border-radius: 0 !important;
    }
    .tableRow {
      &:last-child {
        .tableCell {
          border-bottom: none !important;
        }
      }
    }
    .checkboxCell {
      flex: none;
      align-items: flex-start;
      width: 34px;
      padding-top: $contentDefaultIndent * 1.5;
      padding-left: $contentDefaultIndent * 1.5;
    }
    .transactionCell {
      flex: 2;
      justify-content: flex-end;
      width: 2px;
      min-width: 6rem;
    }
    .amountCell {
      flex: 3;
      justify-content: flex-end;
      width: 3px;
    }
    .extraCell {
      display: flex;
      flex: 16;
      width: 16px;
    }
  }
  .preloader {
    height: 122px;
    border-top: 1px solid $uiBorderColor;
    font-size: 0.5rem;
  }
  .footer {
    display: flex;
    padding: $contentDefaultIndent;
    border-top: 1px solid $uiBorderColor;
    .pagination {
      display: block;
      margin: 0 auto;
    }
  }
}
