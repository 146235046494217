@import "nlib/config";

.extraCell {
  width: 100%;
  height: 100%;
  .description {
    display: flex;
    position: relative;
    align-items: center;
    height: 21px;
    margin-bottom: $contentDefaultIndent;
    overflow: hidden;
    font-size: 0.75rem;
    .content {
      display: flex;
      position: absolute;
      top: 1px;
      left: 0;
      width: 100%;
      .text {
        padding-left: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        svg {
          font-size: 1rem;
          &.warning {
            color: $uiWarningColor;
          }
        }
        &.muted {
          color: $uiPlaceholderColor;
        }
      }
    }
  }
  .fields {
    display: flex;
    justify-content: space-between;
    margin-bottom: $contentDefaultIndent;
  }
  .input {
    flex: 1;
    max-width: 31rem;
    input {
      border-radius: 0;
    }
    + .input {
      margin-left: 0.5%;
    }
    &:first-child {
      input {
        border-top-left-radius: $uiBorderRadius;
        border-bottom-left-radius: $uiBorderRadius;
      }
    }
    &:last-child {
      input {
        border-top-right-radius: $uiBorderRadius;
        border-bottom-right-radius: $uiBorderRadius;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
  }
  input {
    height: 2rem;
  }
}
