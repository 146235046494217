@import "nlib/config";

.section {
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    padding: $contentDefaultIndent 0;
    padding-right: $contentDefaultIndent;
    color: lighten($uiFontColor, 30%);
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    div {
      flex: auto;
    }
    svg {
      transition: transform 0.2s;
      font-size: 1rem;
    }
    &:hover {
      color: $uiFontColor;
    }
  }
  .content {}
  &.collapsed {
    .title {
      svg {
        transform: rotate(180deg);
      }
    }
    .content {
      display: none;
    }
  }
}
