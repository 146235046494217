@import "nlib/config";

.emptyState {
  display: flex;
  flex: auto;
  border: 1px solid $uiBorderColor;
  border-radius: $uiBorderRadius;
  background: $uiWhiteColor;
  .success {
    svg {
      color: $uiPositiveColor;
    }
  }
}
