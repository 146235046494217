@import "nlib/config";

.editRuleModal {
  .content {
    position: relative;
    min-height: 264px;
  }
  .nameWrapper {
    display: flex;
    .name {
      margin-right: 42px;
    }
    .buttons {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-right: 50px;
      margin-left: $contentDefaultIndent * 1;
    }
  }
  .block {
    margin-bottom: $contentDefaultIndent * 1.5;
  }
  .operator {
    margin-bottom: $contentDefaultIndent;
  }
  .switcher {
    width: 280px;
    margin: 0 auto $contentDefaultIndent * 2;
  }
  .cloneReports {
    min-height: 264px;
  }
  .dialogClassName {
    padding-left: 1rem;
  }
  .warning {
    margin-bottom: $contentDefaultIndent;
    text-align: center;
    svg {
      color: $uiWarningColor;
      font-size: 1.2em;
    }
  }
}
