@import "nlib/config";

.header {
  display: flex;
  align-items: center;
  padding: 0;
  .title {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
  }
  .buttons {
    display: flex;
    margin-left: auto;
    padding-left: $contentDefaultIndent * 2;
    button {
      margin-left: $contentDefaultIndent;
    }
  }
}
.content {
  display: flex;
  flex: auto;
  flex-direction: column;
  .emptyState {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 4rem;
    bottom: 0;
    flex: auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1.5rem;
  }
  .overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($uiWhiteColor, 0.5);
  }
  .preloader {
    min-height: 60vh;
  }
  .deleteButton {
    margin-left: $contentDefaultIndent;
  }
}
