@import "nlib/config";

.bulkActions {
  position: relative;
  position: sticky;
  z-index: 5;
  top: $contentDefaultIndent * 2;
  justify-content: flex-end;
  width: 100%;
  padding-top: $contentDefaultIndent / 2 !important;
  padding-bottom: $contentDefaultIndent / 2 !important;
  border: 1px solid $uiPrimaryColor;
  &[disabled] {
    opacity: 1 !important;
    > * {
      opacity: 0.5 !important;
    }
  }
  .text {
    overflow: hidden;
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .inputs {
    display: flex;
    flex: 1;
    margin-top: $contentDefaultIndent * 0.75;
    margin-bottom: $contentDefaultIndent * 0.75;
    margin-left: $contentDefaultIndent * 1.5;
    .input {
      flex: 1;
      min-width: 8rem;
      max-width: 18rem;
      input {
        height: 2rem;
      }
      + .input {
        margin-left: $contentDefaultIndent * 1.5;
      }
    }
  }
  .buttons {
    display: flex;
    margin-top: $contentDefaultIndent * 0.75;
    margin-bottom: $contentDefaultIndent * 0.75;
    margin-left: auto;
    padding-left: $contentDefaultIndent * 1.5;
    button {
      overflow: hidden;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      + button {
        margin-left: $contentDefaultIndent;
      }
    }
  }
}
