@import "nlib/config";

.approvePopup {
  margin-top: $contentDefaultIndent * 2;
  padding-bottom: $contentDefaultIndent * 2;
  .card {
    width: 40rem;
    background-color: $uiPositiveColor;
    color: $uiWhiteColor;
    font-weight: 500;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.5rem;
      height: 1.5rem;
      margin-right: $contentDefaultIndent * 1.5;
      border-radius: 50%;
      background-color: $uiWhiteColor;
      box-shadow: 0 0 0 4px rgba($uiWhiteColor, 0.2);
      color: $uiPositiveColor;
      font-size: 1rem;
    }
    .text {
      margin-right: $contentDefaultIndent;
    }
    .button {
      height: 2.5rem;
      margin-left: auto;
      border-color: $uiWhiteColor;
      background-color: transparent;
      color: $uiWhiteColor;
      font-size: 0.875rem;
      font-weight: 500;
      &:hover {
        background-color: rgba($uiWhiteColor, 0.1);
      }
    }
  }
}
