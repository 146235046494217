@import "nlib/config";

.typeSelect {
  width: 25%;
  min-width: 5.75rem;
  max-width: 7rem;
  button {
    padding-left: $contentDefaultIndent;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .typeSelectDropdownClassName {
    padding: 0;
  }
  + label {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
