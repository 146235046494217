@import "nlib/config";

.rule {
  display: flex;
  position: relative;
  margin-bottom: $contentDefaultIndent;
  .field {
    flex: 4;
    width: 4px;
  }
  .operator {
    flex: 4;
    width: 4px;
    margin-left: $contentDefaultIndent;
  }
  .value {
    flex: 10;
    width: 10px;
    margin-left: $contentDefaultIndent;
  }
  .remove {
    button {
      color: $uiAccentColor;
      svg {
        font-size: 1rem;
      }
      &:hover {
        background-color: transparent !important;
        box-shadow: none !important;
        color: lighten($uiAccentColor, 20%);
      }
    }
  }
  + .rule {
    &::before {
      content: "&";
      position: absolute;
      top: 50%;
      left: -16px;
      transform: translate(0, -50%);
      font-size: 14px;
      font-weight: 500;
    }
  }
}
