@import "nlib/config";

.customizeModal {
  .groupTitleRow {
    margin-bottom: $contentDefaultIndent;
    font-weight: 500;
  }
  .groups {
    &.columns {
      columns: 2;
    }
    + .groupTitleRow {
      margin-top: $contentDefaultIndent * 3;
    }
    .formGroup {
      justify-content: flex-start;
      margin: 0 0 $contentDefaultIndent * 2;
      .title {
        margin-bottom: $contentDefaultIndent;
        font-weight: 500;
      }
    }
  }
  .datePicker {
    width: 9rem;
  }
}
