@import "nlib/config";

.comments {
  display: flex;
  position: relative;
  margin-right: $contentDefaultIndent * 2;
  font-size: 0.6875rem;
  line-height: 1rem;
  white-space: nowrap;
  .button {
    display: flex;
    align-items: center;
    width: 72px;
    height: 2rem;
    padding: 0 $contentDefaultIndent * 1.5;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
    cursor: pointer;
    &:hover {
      background-color: $uiBorderColor;
      color: $uiBlackColor;
    }
    .badge {
      min-width: 21px;
      height: 16px;
      padding: 0 4px;
      border-radius: 8px;
      line-height: 16px;
      text-align: center;
    }
    &:hover {
      text-decoration: none;
    }
    svg {
      font-size: 1rem;
    }
  }
}
.dropdownContent {
  width: 22rem !important;
  max-height: none !important;
  margin: 0;
  overflow: visible;
  &.businessUser {
    min-width: 17rem !important;
    max-width: 22rem !important;
  }
  .commentsBlock {
    max-height: 20rem;
  }
}
