@import "nlib/config";

.filters {
  display: flex;
  height: fit-content;
  padding-top: $contentDefaultIndent * 2;
  padding-bottom: $contentDefaultIndent * 2;
  &.sticky {
    position: sticky;
    z-index: 4;
    top: 0;
    background-color: $uiBackgroundColor;
  }
  .search {
    flex: 2;
    max-width: 24rem;
  }
  .datePicker {
    flex: 1;
    max-width: 14rem;
    margin-left: $contentDefaultIndent;
  }
  .select {
    flex: 1;
    max-width: 14rem;
    margin-left: $contentDefaultIndent;
  }
  .button {
    display: flex;
    visibility: hidden;
    flex: 1;
    align-items: center;
    width: 100px;
    max-width: fit-content;
    margin-left: $contentDefaultIndent;
    padding: 0 0.7em;
    overflow: hidden;
    white-space: nowrap;
    svg {
      min-width: 1em;
    }
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.shown {
      visibility: visible;
    }
  }
}
