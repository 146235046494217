@import "nlib/config";

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.375rem;
  overflow: hidden;
  .name {
    margin-right: $contentDefaultIndent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .badge {
    flex: none;
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
