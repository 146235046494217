@import "nlib/config";

.contact {
  .vendorValidation {
    display: none;
    &:hover {
      display: block;
    }
  }
  .confidenceAppend:hover {
    + .vendorValidation {
      display: block;
    }
  }
}
