@import "nlib/config";

.bulkActions {
  position: relative;
  position: sticky;
  z-index: 5;
  top: $contentDefaultIndent * 2;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  margin-top: $contentDefaultIndent * 2;
  padding-top: $contentDefaultIndent * 1.25 !important;
  padding-right: $contentDefaultIndent * 2;
  padding-bottom: $contentDefaultIndent * 1.25 !important;
  padding-left: $contentDefaultIndent * 2;
  border: 1px solid $uiPrimaryColor;
  &[disabled] {
    opacity: 1 !important;
    > * {
      opacity: 0.5 !important;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $contentDefaultIndent;
  }
  .content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .inputs {
    display: flex;
    flex: 1;
    margin-top: $contentDefaultIndent * 0.75;
    margin-bottom: $contentDefaultIndent * 0.75;
    margin-left: $contentDefaultIndent * 1.5;
    .input {
      flex: 1;
      min-width: 12rem;
      max-width: 18rem;
      + .input {
        margin-left: $contentDefaultIndent * 1.5;
      }
    }
  }
  .buttons {
    display: flex;
    margin-top: $contentDefaultIndent * 0.75;
    margin-bottom: $contentDefaultIndent * 0.75;
    margin-left: auto;
    padding-left: $contentDefaultIndent * 1.5;
    button + button {
      margin-left: $contentDefaultIndent;
    }
  }
  .selectStatus {
    flex: 1;
    max-width: 20rem;
    margin-left: $contentDefaultIndent * 1.5;
  }
}
