@import "nlib/config";

.unusualVendorCategoriesTable {
  position: relative;
  flex: auto;
  padding: 0 !important;
  .content {
    display: flex;
    flex: auto;
    flex-direction: column;
    .tableWrap {
      .table {
        font-size: $uiDesktopFontSize;
        .tableRow {
          .tableCell {
            width: 100%;
            background-color: $uiWhiteColor;
          }
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: $contentDefaultIndent * 2;
      margin-bottom: $contentDefaultIndent * 2;
      .pagination {
        margin-right: $contentDefaultIndent;
      }
    }
  }
  .itemContainer {
    width: 100%;
    padding-bottom: $contentDefaultIndent;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      padding-right: $contentDefaultIndent + 1;
      padding-bottom: $contentDefaultIndent;
      overflow: hidden;
      .title {
        display: flex;
        align-items: center;
        height: 1.25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        svg {
          font-size: 1rem;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .slash {
          margin-left: 0.3em;
        }
        .part {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .actions {
        display: flex;
        button {
          + button {
            margin-left: $contentDefaultIndent * 2;
          }
        }
        .mergeButton {
          width: 138px;
        }
      }
    }
    .subTable {
      border: 1px solid $uiBorderColor;
      border-radius: $uiBorderRadius;
    }
  }
}
