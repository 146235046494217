@import "nlib/config";
@import "animations";

.auditPage {
  .preloader {
    height: 54%;
    height: calc(100vh - 12rem);
    margin: 0;
  }
  .showAllButton {
    margin-right: $contentDefaultIndent;
  }
  .pageHeaderActions {
    display: flex;
  }
  .groupedByMonth {
    margin-left: $contentDefaultIndent * 2;
    border-radius: $contentDefaultIndent;
    background-color: $uiWhiteColor;
    button {
      min-width: 5rem;
    }
  }
  .monthsTabs {
    margin-bottom: $contentDefaultIndent;
  }
  .container {
    display: flex;
    flex: auto;
    .sidebar {
      width: 16%;
      min-width: 12rem;
      max-width: 14rem;
      margin-right: $contentDefaultIndent;
      margin-bottom: 1rem;
      .header {
        display: flex;
        padding: $contentDefaultIndent $contentDefaultIndent * 1.5 $contentDefaultIndent * 1.5;
      }
      .sections {
        top: 16px;
        padding: 0 0 1rem;
        .section {
          .items {
            a {
              &:not([href]) {
                color: $uiNeutralColor;
                cursor: default;
              }
              &.active {
                cursor: default;
              }
            }
            a[href],
            .button {
              border-radius: $uiBorderRadius;
              cursor: pointer;
              &.active,
              &:hover {
                background-color: darken($uiBackgroundColor, 4%);
              }
              &:active {
                background-color: darken($uiBackgroundColor, 6%);
              }
            }
            .item {
              display: flex;
              align-items: center;
              width: 100%;
              height: 2.25rem;
              margin-bottom: 0.25rem;
              padding-right: $contentDefaultIndent / 2;
              padding-left: $contentDefaultIndent * 1.5;
              color: inherit;
              text-decoration: none;
              &.allCategorized {
                height: 2.5rem;
              }
              .icon {
                font-size: 0.75rem;
              }
              .text {
                display: block;
                margin-right: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .counterWrap {
                display: flex;
                justify-content: center;
                margin-left: $contentDefaultIndent;
                .wait {
                  animation: rotate 2s linear infinite;
                }
              }
              .badge {}
              svg {
                font-size: 1.2rem;
              }
            }
            .itemWrap {
              display: flex;
              align-items: center;
              margin-bottom: 0.25rem;
              overflow: hidden;
              .item {
                flex: auto;
                margin-bottom: 0;
                overflow: hidden;
              }
            }
            .dropdown {
              .menuItem {
                padding-right: 1.25rem;
                padding-left: 1rem;
              }
            }
          }
        }
      }
    }
    .transactionsContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: none;
    }
  }
}
