@import "nlib/config";

.transactionsTable {
  margin-bottom: 1rem;
  white-space: nowrap;
  svg {
    font-size: 1rem;
  }
  .readyToApprove {}
  .selected {}
  .checkboxCell {
    flex: none;
    align-items: flex-start;
    width: 3rem;
    padding-top: $contentDefaultIndent * 1.5;
    padding-left: $contentDefaultIndent * 1.5;
  }
  .transactionCell {
    flex: 6;
    justify-content: flex-end;
    width: 6px;
    min-width: 7rem;
  }
  .amountCell {
    flex: 5;
    justify-content: flex-end;
    width: 5px;
    min-width: 8rem;
  }
  .extraCell {
    flex: 52;
    width: 52px;
  }
  .transactionCell,
  .amountCell,
  .extraCell {
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
  }
  .duplicateRow {
    .transactionCell {
      position: relative;
      .transactionCellContent {
        position: relative;
        max-width: calc(100% - 2.5rem);
        padding-top: $contentDefaultIndent;
        padding-bottom: $contentDefaultIndent;
      }
      .duplicate {
        position: absolute;
        left: -1rem;
        flex: auto;
        width: 2.5rem;
        height: 100%;
        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0.5rem;
          border-left: 1px solid $uiDarkColor;
        }
        &::before {
          top: 0;
          height: 0.5rem;
        }
        &::after {
          top: 2rem;
          bottom: 0;
        }
        svg {
          margin-top: $contentDefaultIndent * 1.375;
          font-size: 1rem;
        }
        div {
          position: absolute;
          top: 1.125rem;
          right: 0.5rem;
          left: 1.25rem;
          border-top: 1px solid $uiDarkColor;
        }
      }
    }
    &.firstOfGroup,
    &.lastOfGroup {
      .transactionCell {
        .duplicate {
          &::after {
            display: none;
          }
          svg,
          div {
            display: none;
          }
        }
      }
    }
    &.firstOfGroup {
      .transactionCell {
        .duplicate {
          &::before {
            top: 1.25rem;
            bottom: 0;
            width: 0.75rem;
            height: auto;
            border-top: 1px solid $uiDarkColor;
            border-top-left-radius: 0.5rem;
          }
        }
      }
    }
    &.lastOfGroup:not(.firstOfGroup) {
      .transactionCell {
        .duplicate {
          &::before {
            top: 0;
            width: 0.75rem;
            height: 1.25rem;
            border-bottom: 1px solid $uiDarkColor;
            border-bottom-left-radius: 0.5rem;
          }
        }
      }
    }
  }
  .tableRowHeader {
    .transactionCell {
      > div {
        margin-left: $contentDefaultIndent * 0.75;
      }
    }
  }
}
.footer {
  display: flex;
  .pagination {
    margin-right: $contentDefaultIndent * 2;
  }
}
